/* Webfont: SailecMedium */@font-face {
    font-family: 'SailecMedium';
    src: url('sailec-medium.eot');
    src: url('sailec-medium.eot?#iefix') format('embedded-opentype'),
         url('sailec-medium.woff2') format('woff2'),
         url('sailec-medium.woff') format('woff');
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

/* Webfont: SailecRegular */@font-face {
    font-family: 'SailecRegular';
    src: url('sailec-regular.eot');
    src: url('sailec-regular.eot?#iefix') format('embedded-opentype'),
         url('sailec-regular.woff2') format('woff2'),
         url('sailec-regular.woff') format('woff');
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'InterRegular';
    font-style:  normal;
    font-weight: 400;
    font-display: swap;
    src: url("inter-regular.woff2") format("woff2"),
         url("inter-regular.woff") format("woff");
}
@font-face {
    font-family: 'InterSemibold';
    font-style:  normal;
    font-weight: 600;
    font-display: swap;
    src: url("inter-semibold.woff2") format("woff2"),
         url("inter-semibold.woff") format("woff");
}